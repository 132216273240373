import React, { useState } from "react";
import Slider from "react-slick";
import { Container, Breadcrumb, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import banner from '../assets/images/ourbrand.jpg'
import aboutBannerLogo from '../assets/images/barndlogo.png'

import cementLogo from '../assets/images/cement_logo.svg';
import rmxconcreto from '../assets/images/rmxconcreto.png';


import dcement from '../assets/images/dcement.png';
import duraguard50kg from '../assets/images/duraguard50kg.png';
import duraguardmicrofibre50kg from '../assets/images/duraguardmicro-fibre-50kg.png';
import duraguardrapidx50kg53 from '../assets/images/duraguard-rapidx50kg-53.png';
import duraguardsilver50kg from '../assets/images/duraguardsilver50kg.jpg';
import duraguardwaterseal50kg from '../assets/images/duraguard-waterseal-50kg.png';
import duraguardxtra50kg from '../assets/images/duraguard-xtra-50kg.png';

import masterpremiumcement from '../assets/images/masterpremiumcement.png';
import ppcbag from '../assets/images/ppcbag.png';
import pscbag from '../assets/images/pscbag.png';
import subhpremiumpsc from '../assets/images/subhpremiumpsc.png';
import pscadstarbags from '../assets/images/pscadstarbags.png';

import nirmax50kg from '../assets/images/nirmax50kg.png';
import nirmaxbags01 from '../assets/images/nirmaxbags01.png';
import Infracem50kg from '../assets/images/Infracem50kg.png';
import ppcpscprocem from '../assets/images/ppcpsc-procem.png';
import ppc43 from '../assets/images/ppc43.png';
import ppc53 from '../assets/images/ppc43.png';
import concretoUno from '../assets/images/Concreto-uno-50kg.png';



import instamixbaggedconcrete from '../assets/images/instamixbaggedconcrete.png';
import instamixmicrone from '../assets/images/instamixmicrone.png';
import instamixmortareradymixmortar from '../assets/images/instamixmortareradymixmortar.png';

import instamixbondaid50kg from '../assets/images/instamixbondaid50kg.png';
import instamixplastosmart from '../assets/images/instamixplastosmart.png';
import instamixxpress from '../assets/images/instamixxpress.png';
import xconlogo from '../assets/images/xconlogo.png';
import artistelogo05 from '../assets/images/artistelogo05.png';

import zeromacrylicpower from '../assets/images/zeromacrylicpower3.png';
import zeromcoverblock from '../assets/images/zeromcoverblock8.png';
import zeromgermicheckwallputty from '../assets/images/zeromgermicheckwallputty6.png';
import zeromlatexexpert from '../assets/images/zeromlatexexpert4.png';
import zerompolyrichwallputty from '../assets/images/zerompolyrichwallputty7.png';
import zeromspeedextileadhesive from '../assets/images/zeromspeedextileadhesive5.png';
import zeromspeedextilegrout from '../assets/images/zeromspeedextilegrout9.png';

import zeromwatershieldkee from '../assets/images/zeromwatershieldkee.jpeg';
import zeromwatershieldiwc from '../assets/images/zeromwatershieldiwc1.png';
import zerowatershield from '../assets/images/zerowatershield2.png';

import concretoagile from '../assets/images/concreto-agile.png';
import concretoagileflow from '../assets/images/concreto-agile-flow.png';
import concretocorrosafeplus from '../assets/images/concreto-corrosafe-plus.png';
import concretocwtplus from '../assets/images/concreto-cwt-plus.png';
import concretoeasyfille from '../assets/images/concreto-easyfille.png';
import concretoecodure from '../assets/images/concreto-ecodure.png';
import concretofluide from '../assets/images/concreto-fluide.png';
import concretoinstante from '../assets/images/concreto-instante.png';
import concretolente from '../assets/images/concreto-lente.png';
import concretopermadure from '../assets/images/concreto-permadure.png';
import concretopolibre from '../assets/images/concreto-polibre.png';
import concretoregletherme from '../assets/images/concreto-regletherme.png';
import concretorobuste from '../assets/images/concreto-robuste.png';
import concretosteelibre from '../assets/images/concreto-steelibre.png';
import concretoxlite from '../assets/images/concreto-xlite.png';
import concretoxlitestructure from '../assets/images/concreto-xlite-structure.png';
import concretoglydelogo from '../assets/images/concreto-glyde-logo.png';



import artistsengrave from '../assets/images/artists-engrave.png';
import artistshug from '../assets/images/artists-hug.png';
import artistbare from '../assets/images/artist-bare.png';
import artistscoarse from '../assets/images/artists-coarse.png';
import artistlumos from '../assets/images/artist-lumos.png';




import dbull from '../assets/images/dbull.png';
import nirmex from '../assets/images/nirmex.png';
import psc from '../assets/images/psc.png';
import infracem from '../assets/images/infracem.png';
import artiste from '../assets/images/artiste.png';
import concreto from '../assets/images/concreto.png';
import ecodure from '../assets/images/ecodure.png';
import instamix from '../assets/images/instamix.png';
import xcon from '../assets/images/xcon.png';
import zeroM from '../assets/images/zeroM.png';
import concreto01 from '../assets/images/concreto.jpg'
import green from '../assets/images/green.jpg'
import ecodureproduct from '../assets/images/ecodure-product.png'
import { Helmet } from 'react-helmet';

const settings = {
    //centerMode: true,
    //autoplay:true,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};

const settings1 = {
    //centerMode: true,
    //autoplay:true,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};


const OurBarnd = () => {
    const [one, setOne] = useState(false);
    const closeOne = () => setOne(false);

    const [two, setTwo] = useState(false);
    const closeTwo = () => setTwo(false);

    const [three, setThree] = useState(false);
    const closeThree = () => setThree(false);

    const [four, setFour] = useState(false);
    const closeFour = () => setFour(false);

    const [five, setFive] = useState(false);
    const closeFive = () => setFive(false);

    const [six, setSix] = useState(false);
    const closeSix = () => setSix(false);

    const [seven, setSeven] = useState(false);
    const closeSeven = () => setSeven(false);

    const [eight, setEight] = useState(false);
    const closeEight = () => setEight(false);

    const [nine, setNine] = useState(false);
    const closeNine = () => setNine(false);

    const [ten, setTen] = useState(false);
    const closeTen = () => setTen(false);

    const [eleven, setEleven] = useState(false);
    const closeEleven = () => setEleven(false);


    const [twelve, setTwelve] = useState(false);
    const closeTwelve = () => setTwelve(false);

    const [thirteen, setThirteen] = useState(false);
    const closeThirteen = () => setThirteen(false);



    const showComponent = ((varname) => {
        switch (varname) {
            case "one":
                setOne(true);
                break;
            case "two":
                setTwo(true);
                break;
            case "three":
                setThree(true);
                break;
            case "four":
                setFour(true);
                break;
            case "five":
                setFive(true);
                break;
            case "six":
                setSix(true);
                break;
            case "seven":
                setSeven(true);
                break;
            case "eight":
                setEight(true);
                break;
            case "nine":
                setNine(true);
                break;
            case "ten":
                setTen(true);
                break;
            case "eleven":
                setEleven(true);
                break;
            case "twelve":
                setTwelve(true);
                break;
            case "thirteen":
                setThirteen(true);
                break;
            default: return;
        }
    })

    return (
        <div className="our_brand_inner">
    <Helmet>
    <title>Our Brands | Nuvoco Vistas</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is offering wide range of products across cement ,ready mix concrete and modern building material categories with great range of durable products" />
    <link rel="canonical" href="https://nuvoco.com/our-brands"/>
    </Helmet>
            <div className="banner text-center position-relative">
                <img className='img_round' src={banner} alt="" />
                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">Our brands</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Our brands
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className="card_round pt-5">
                <Container>
                    <h2 className='grad-heading pd-5 mb-5'>Cement</h2>
                    <Row className="pb-4">
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('one')}>
                                <Card.Img src={cementLogo} />
                                <Card.Body>
                                    <article>
                                        Nuvoco’s premium Concreto range offers products that are carefully created after incorporating the latest research and development specializing in low water consumption and other eco-friendly variants. Concreto is a frontrunner in Eastern India and has a specialized variant for Northern India.
                                    </article>
                                    <Card.Title className="g_invert">
                                        Concreto
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                         <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('two')}>
                                <Card.Img src={dcement} />
                                <Card.Body>
                                    <article>
                                        Nuvoco’s flagship brand Duraguard is one of the most popular cement brands in the Northern and Eastern markets of India and has become a symbol of trust and longevity. It includes a portfolio of technologically advanced products for modern construction requirements.
                                    </article>
                                    <Card.Title className="g_invert">
                                        Duraguard
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col> 

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('three')}>
                                <Card.Img src={dbull} />
                                <Card.Body>
                                    <article>
                                        Nuvoco’s latest brand Double Bull Cement is known as one of the fastest-growing cement brands in its operating markets. It includes a range of premium products like Master (PPC) and SUBH (PSC) along with variants for trade and institutional sales that are manufactured in technologically advanced plants using high- grade clinker.
                                    </article>
                                    <Card.Title className="g_invert">
                                        Double Bull
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('four')}>
                                <Card.Img src={psc} />
                                <Card.Body>
                                    <article>
                                        Introduced in 1993, Premium Slag Cement is the oldest offering from the house of Nuvoco created from a unique mix of ingredients providing unparalleled strength, excellent shine and smooth finish.
                                    </article>
                                    <Card.Title className="g_invert">
                                       PSC
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('five')}>
                                <Card.Img src={nirmex} />
                                <Card.Body>
                                    <article>
                                        Nirmax was launched in 2014 in the northern markets of India with a strong presence in Rajasthan. It has become a reliable brand for home builders in North India due to its highest ethical standards (it undergoes stringent quality checks at every level of production) and the latest technology.
                                    </article>
                                    <Card.Title className="g_invert">
                                        Nirmax
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('six')}>
                                <Card.Img src={infracem} />
                                <Card.Body>
                                    <article>
                                        Infracem are best suited for heavy construction work including mass concreting, foundations and RCC work. It offers consistent quality and delivers high performance in infrastructure projects.
                                    </article>
                                    <Card.Title className="g_invert">
                                    Infracem
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <h2 className='grad-heading pd-5 mb-5'>Ready-Mix Concrete</h2>
                    <Row className="pb-4 justify-content-center">
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('seven')}>
                                <Card.Img src={rmxconcreto} />
                                <Card.Body>
                                    <article>
                                        The Concreto range of products offers enhanced performance with variants like self-compacting concrete, thermal insulated concrete, low density concrete.
                                    </article>
                                    <Card.Title className="g_invert">
                                        Concreto
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('eight')}>
                                <Card.Img src={instamix} />
                                <Card.Body>
                                    <article>
                                        InstaMix range offers ready-to-use concrete and mortar that is available in 35 kg bags and delivered straight to the job site. It is easy to use and gives consistent quality with minimal wastage.
                                    </article>
                                    <Card.Title className="g_invert">
                                        InstaMix
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('nine')}>
                                <Card.Img src={artiste} />
                                <Card.Body>
                                    <article>
                                        Artiste is a range of decorative concrete that combines freedom of design with low maintenance and superior durability. It offers wide variety of choices for the modern builder to match today’s trends in the world of new-age construction.
                                    </article>
                                    <Card.Title className="g_invert">
                                        Artiste
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('ten')}>
                                <Card.Img src={xcon} />
                                <Card.Body>
                                    <article>
                                        X-CON is a widely preferred standard  concrete (Grade M5 to M95) that is produced in an automated batching plant using a mixture of cement, water, sand and aggregates (including 10 and 20 mm stones or gravels). This range is popular for its consistent quality, on-time delivery and high-quality service.
                                    </article>
                                    <Card.Title className="g_invert">
                                        X-CON
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('eleven')}>
                                <Card.Img src={ecodure} />
                                <Card.Body>
                                    <article>
                                        Ecodure is a revolutionary range of Low carbon concrete or green concrete that can reduce the carbon emissions up to 60% in comparison with standard OPC mix. Its range of concrete includes Ecodure, Ecodure
                                        Prime, and Ecodure Plus.
                                    </article>
                                    <Card.Title className="g_invert">
                                        Ecodure
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <h2 className='grad-heading pd-5 mb-5'>Modern Building Materials</h2>
                    <Row className="pb-4 justify-content-center">
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('twelve')}>
                                <Card.Img src={zeroM} />
                                <Card.Body>
                                    <article>
                                        Our Zero M range includes Construction Chemicals that protect the construction from seepage, peeling and cracks, Wall Putty, Tile Adhesives, Wall-fill solutions for architectural freedom and Cover Blocks for strong, lightweight and stable frameworks.
                                    </article>
                                    <Card.Title className="g_invert">
                                        Zero M
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('thirteen')}>
                                <Card.Img src={instamix} />
                                <Card.Body>
                                    <article>
                                        Our InstaMix range is a careful selection of products that make the construction process convenient. It is the immediate solution for all construction needs that require ready-to-use, pre-mixed products and help in saving time, cost, and other resources.
                                    </article>
                                    <Card.Title className="g_invert">
                                        InstaMix
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                </Container >
            </div>


            <Modal size="lg" centered="true"
                show={one} onHide={closeOne} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={cementLogo} alt='' />
                                <article className="p-4">
                                    Nuvoco’s premium Concreto range offers products that are carefully created after incorporating the latest research and development specializing in low water consumption and other eco-friendly variants.
                                    Concreto is a frontrunner in Eastern India and has a specialized variant for Northern India.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concreto01} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-cement">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoUno} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Uno
                            </Card.Title>
                            <Button variant="success" className="read_more" href="/products/concreto-uno">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                                <Col md={4}>
                                    {/* <Card>
                                        <Card.Img src={green} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Green
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-green-cement">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                                

                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={two} onHide={closeTwo} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center logospacepop">
                                <img src={dcement} alt='' />
                                <article className="p-4">
                                    Nuvoco’s flagship brand Duraguard is one of the most popular cement brands in the Northern and Eastern markets of India and has become a symbol of trust and longevity. It includes a portfolio of technologically advanced products for modern construction requirements.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>



                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardsilver50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                F2F
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/duraguard-f2f">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardwaterseal50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Waterseal Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/duraguard-waterseal-cement">Read More </Button>
                                        </Card.Body>
                                    </Card> 
                                </Col>*/}


                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardmicrofibre50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Microfiber Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/duraguard-microfiber-cement">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardxtra50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Xtra Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/duraguard-xtra-cement">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardrapidx50kg53} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            RapidX Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/duraguard-rapidx-cement">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                <Card>
                                        <Card.Img src={duraguard50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Duraguard Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/duraguard-cement">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>



                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={three} onHide={closeThree} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center logospacepop">
                                <img src={dbull} alt='' />
                                <article className="p-4">
                                    Nuvoco’s latest brand Double Bull Cement is known as one of the fastest-growing cement brands in its operating markets. It includes a range of premium products like Master (PPC) and SUBH (PSC) along with variants for trade and institutional sales that are manufactured in technologically advanced plants using high- grade clinker.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={masterpremiumcement} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Master Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-master-cement">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={pscbag} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                PSC
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-psc">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ppcbag} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                PPC
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-ppc">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={four} onHide={closeFour} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center logospacepop">
                                <img src={psc} alt='' />
                                <article className="p-4">
                                    Introduced in 1993, Premium Slag Cement is the oldest offering from the house of Nuvoco created from a unique mix of ingredients providing unparalleled strength, excellent shine and smooth finish.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={pscadstarbags} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                PSC
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="products/p-s-c">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>

                                </Col>
                                <Col md={4}>

                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={five} onHide={closeFive} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center logospacepop">
                                <img src={nirmex} alt='' />
                                <article className="p-4">
                                    Nirmax was launched in 2014 in the northern markets of India with a strong presence in Rajasthan. It has become a reliable brand for home builders in North India due to its highest ethical standards (it undergoes stringent quality checks at every level of production) and the latest technology.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={nirmax50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Nirmax Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nirmax-ppc">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={nirmaxbags01} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Nirmax 43/53
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nirmax-opc">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>

                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={six} onHide={closeSix} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={infracem} alt='' />
                                <article className="p-4">
                                    Infracem are best suited for heavy construction work including mass concreting, foundations and RCC work. It offers consistent quality and delivers high performance in infrastructure projects.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={Infracem50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Infracem
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/infracem-cement">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                {/* <Card>
                                        <Card.Img src={ppcpscprocem} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Procem
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-procem-cement">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                            
                                <Col md={4}>
                                {/* <Card>
                                        <Card.Img src={ppc43} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Cement OPC 43
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-opc-43">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                                    {/* 
                                <Col md={4}>
                                <Card>
                                        <Card.Img src={ppc53} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Cement OPC 53
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-opc-53">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col> */}

                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={seven} onHide={closeSeven} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={rmxconcreto} alt='' />
                                <article className="p-4">
                                    The Concreto range of products offers enhanced performance with variants like self-compacting concrete, thermal insulated concrete, low density concrete.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoagile} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Concreto Agile
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-agile">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretorobuste} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Concreto Robuste
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-robuste">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretofluide} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Concreto Fluide
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-fluide%C2%A0">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoinstante} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Instante
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-instante">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoregletherme} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Regletherme
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-regletherme">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretocorrosafeplus} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Corrosafe
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-corrosafe-plus">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretopermadure} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Permadure
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-permadure">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretosteelibre} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Steelibre
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-steelibre">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoagileflow} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Agile Flow
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-agile-flow">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoxlite} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Xlite
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-xlite">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoeasyfille} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Easyfille

                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-easyfille">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretolente} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Lente
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-lente">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretocwtplus} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto CWT Plus
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-cwt-plus">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoxlitestructure} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Xlite Structure
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-xlite-structure">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoecodure} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Concreto Endura
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-endura">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretopolibre} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Polibre
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-polibre">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoglydelogo} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Concreto Glyde
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-glyde">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                




                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={eight} onHide={closeEight} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={instamix} alt='' />
                                <article className="p-4">
                                    InstaMix range offers ready-to-use concrete and mortar that is available in 35 kg bags and delivered straight to the job site. It is easy to use and gives consistent quality with minimal wastage.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixbaggedconcrete} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            InstaMix
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/instamix">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixmicrone} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            InstaMix Microne
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/instamix-microne">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixmortareradymixmortar} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Instamix Mortare
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/instamix-mortare">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={nine} onHide={closeNine} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={artiste} alt='' />
                                <article className="p-4">
                                    Artiste is a range of decorative concrete that combines freedom of design with low maintenance and superior durability. It offers wide variety of choices for the modern builder to match today’s trends in the world of new-age construction.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistelogo05} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Signature
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistsengrave} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Engrave
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-engrave">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistshug} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Hue
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-hue">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistbare} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Bare
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-bare">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistscoarse} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Coarse
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-coarse">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistlumos} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Lumos
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-lumos">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={ten} onHide={closeTen} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={xcon} alt='' />
                                <article className="p-4">
                                    X-CON is a widely preferred standard concrete (Grade M5 to M95) that is produced in an automated batching plant using a mixture of cement, water, sand and aggregates (including 10 and 20 mm stones or gravels). This range is popular for its consistent quality, on-time delivery and high-quality service.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={xconlogo} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                X-CON
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="products/x-con">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>

                                </Col>
                                <Col md={4}>

                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={eleven} onHide={closeEleven} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={ecodure} alt='' />
                                <article className="p-4">
                                    Ecodure is a revolutionary range of Low carbon concrete or green concrete that can reduce the carbon emissions up to 60% in comparison with standard OPC mix. Its range of concrete includes Ecodure, Ecodure Prime, and Ecodure Plus.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Ecodure
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/ecodure">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Ecodure Prime
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/ecodure-prime">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Ecodure Plus
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/ecodure-plus">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={twelve} onHide={closeTwelve} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={zeroM} alt='' />
                                <article className="p-4">
                                    Our Zero M range includes Construction Chemicals that protect the construction from seepage, peeling and cracks, Wall Putty, Tile Adhesives, Wall-fill solutions for architectural freedom and Cover Blocks for strong, lightweight and stable frameworks.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromwatershieldiwc} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Water Shield IWC+
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="products/nuvoco-zero-m-iwc">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zerowatershield} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Water Shield
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/zero-m-water-shield">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromacrylicpower} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Acrylic Power
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-multipurpose-acrylic-expert">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromlatexexpert} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Latex Expert
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-multipurpose-latex-expert">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                          
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromgermicheckwallputty} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            GermiCheck Wall Putty
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/zero-m-germicheck-wall-putty">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zerompolyrichwallputty} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Wall Putty
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="products/zero-m-wall-putty">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromcoverblock} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Cover Blocks
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-cover-block">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromwatershieldkee} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Watershield 2K
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-2k">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromspeedextilegrout} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Speedex Tile Grout
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-tile-grout">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>



                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={thirteen} onHide={closeThirteen} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={instamix} alt='' />
                                <article className="p-4">
                                    Our InstaMix range is a careful selection of products that make the construction process convenient. It is the immediate solution for all construction needs that require ready-to-use, pre-mixed products and help in saving time, cost, and other resources.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixplastosmart} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Plastosmart
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-dry-plaster">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixbondaid50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Bond-Aid
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-block-jointing-mortar">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixxpress} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Xpress
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/instamix-xpress-dry-bag-concrete">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

        </div >
    )
}

export default OurBarnd;